.tooltip {
  --enter-transform: translateY(0px) scale(1);
  --exit-transform: translateY(12px) scale(0.8);

  display: inline-block;
  color: hsl(0, 0%, 80%);
  font-size: 13px;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  z-index: 10;
  border-radius: 2px;
  padding: 2px 8px;
  will-change: transform, opacity;
}

.tooltip[data-visible] {
  animation: media-tooltip-enter 0.2s ease-in;
  animation-fill-mode: forwards;
}

.tooltip:not([data-visible]) {
  animation: media-tooltip-exit 0.2s ease-out;
}

.tooltip[data-placement~='bottom'] {
  --exit-transform: translateY(-2px) scale(0.8);
}

@keyframes media-tooltip-enter {
  from {
    opacity: 0;
    transform: var(--exit-transform);
  }
  to {
    opacity: 1;
    transform: var(--enter-transform);
  }
}

@keyframes media-tooltip-exit {
  from {
    opacity: 1;
    transform: var(--enter-transform);
  }
  to {
    opacity: 0;
    transform: var(--exit-transform);
  }
}

:global(.menu[data-open]) .tooltip {
  display: none !important;
}
