.pdfThumb #pdf-controls {
    display: none !important;
}

.pdfThumb #pdf-page-wrapper {
    margin: 0px !important;
}

.pdfThumb .react-pdf__Page {
    height: 165px !important;
}

.pdfThumb .react-pdf__Page__canvas {
    width: 200px !important;
    height: 165px !important;
}

.pdfThumb .react-pdf__Page__annotations {
    display: none !important;
}

.pdfThumb .react-pdf__Page__textContent {
    display: none !important;
}
#pdf-page-info{
    color: #FFF !important;
}