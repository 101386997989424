body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter";
}

/* width  */
::-webkit-scrollbar {
  width: 0px;
  border-radius: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #d2d2d0; */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbb;
  border-radius: 10px;
}
.custom-prev-arrow {
  position: absolute;
  top: -53px;
  right: 58px;
}
.custom-next-arrow {
  position: absolute;
  top: -53px;
  right: 24px;
}
.hoverDiv:hover {
  border: 2px solid #007aff;
  box-shadow: -6px 6px 0px 0px rgba(0, 122, 255, 0.5);
}
.hoverDiv:hover .hoverOpacity {
  opacity: 0.3;
}
@media screen and (max-width: 600px) {
  .hoverDiv:hover .hoverOpacity {
    opacity: 1;
  }
  .hoverDiv:hover {
    border: 0;
    box-shadow: none;
  }
  .mute-button,
  .volume-slider {
    display: none !important;
  }
  .play-button,
  .pip-button,
  .fullscreen-button,
  .vds-button {
    width: 28px !important;
    height: 28px !important;
  }
  .play-button {
    margin-bottom: 5px !important;
  }
}
.hoverDiv:hover .hoverShow {
  display: block;
}
.hoverDiv:hover .hoverVideControl {
  display: block;
}
.slick-slide {
  margin: 0 8px;
  max-width: 246px;
}

.courseSlider .slick-list .slick-track {
  display: block !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop,
.css-919eu4 {
  width: 10% !important;
  background-color: transparent !important;
}

.courseSlider .slick-disabled {
  opacity: 0;
}
.courseSlider .slick-prev {
  position: absolute;
  top: -42px;
  left: 85%;
}
.courseSlider .slick-next {
  position: absolute;
  top: -42px;
  right: 24px;
}
