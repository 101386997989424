.pdfMain{position: relative;}

.pdfMain #pdf-controls {
  background-color: #2b3258cc !important;
  position: absolute !important;
  top: 0;
  z-index: 3;
  width: 100% !important;
  padding: 8px 0 !important;
  justify-content: unset !important;
  border-radius: 16px 16px !important;
}

.pdfMain #pdf-pagination {
  position: absolute;
  right: 35px;
}

.pdfMain #pdf-toggle-pagination {
  margin-right: 78px !important;
}

.pdfMain #pdf-download {
  display: none !important;
}

.pdfMain #pdf-pagination-info {
  font-weight: 400;
  color: #fff;
}
.react-pdf__Document {
  margin: auto !important;
  margin-top: 50px !important;
}
#search-plus {
  fill: #fff !important;
}
#pdf-zoom-reset path {
  fill: #fff !important;
}
#pdf-renderer button {
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
#pdf-toggle-pagination path {
  fill: #fff !important;
}
#pdf-pagination path {
  fill: #fff !important;
}
#pdf-zoom-out svg, #pdf-zoom-in svg {
  width: 100% !important;
  height: 100% !important;
}



