.container {
  position: relative;
}

.title {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}
.player {
  --media-brand: #FFA737;
  --media-focus-ring-color: #4e9cf6;
  --media-focus-ring: 0 0 0 3px var(--media-focus-ring-color);

  --media-tooltip-y-offset: 30px;
  --media-menu-y-offset: 30px;
  --media-border-radius: 17px;

  aspect-ratio: 9 /6;
  /* background-color: #212121; */
  border-radius: var(--media-border-radius);
  color: #f5f5f5;
  contain: layout;
  height: 525px;
  border-radius: 17px;
  background-color: #000;
  font-family: Inter;
  overflow: hidden;
}

.player[data-focus]:not([data-playing]) {
  box-shadow: var(--media-focus-ring);
}

.player video,
.poster {
  border-radius: var(--media-border-radius);
  height: 525px;
}

.poster {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.poster[data-visible] {
  opacity: 1;
}

.poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .player {
    height: 300px !important;
  }
  video{
    height: 280px !important;
  }
}