/*************************************************************************************************
 * Buttons
 *************************************************************************************************/

.button {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  /* Resets. */
  padding: 0;
  user-select: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  touch-action: manipulation;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Easy way to hide all buttons that are not supported in current env (e.g. fullscreen/pip). */
.button[aria-hidden='true'] {
  display: none;
}

.button > svg {
  width: 80%;
  height: 80%;
  border-radius: 2px;
}

.button[data-focus] > svg {
  box-shadow: var(--focus-ring);
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    background-color: rgb(255 255 255 / 0.2);
  }
}
